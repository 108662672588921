@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,900');
@media only screen and (max-width: 800px) {
    .login-left-subcontainer{
        display: none
    }
    .login-container{
        min-height: 450px !important; 
        padding-bottom: 30px
    }
  }
  @media only screen and (min-width: 800px) {
      .only-mobile{
          display: none
      }
  }
  .login-page{
      background-color: #f5f7fa;
      position: fixed; 
      top: 0; 
      right: 0; 
      left: 0; 
      bottom: 0;
      display: flex; 
      justify-content: center;
      align-items: center;
      padding: 20px;
  }
  .login-container{
      height: 680px; 
      width: 840px; 
      background-color: white; 
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      box-shadow: 0px 0px 10px 10px rgba(82, 81, 97, 0.06);
      border: 1px solid white
  }
  
  .information-tab{
      border-bottom: 1px solid rgb(203, 213, 218);
      display: flex; 
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 6px; 
  }
  .login-page .information-tab p{
      color: rgb(106, 125, 128);
      font-size: 15px;
      text-align: center
  }
  .login-page .login-left-subcontainer{
      flex: 1;
      background-color: #dadfe2;
      padding-left: 30px;
  }
  .login-page .big-image{
      object-fit: fill;
      width: 94%;
      transform: translate(0px, 60px);
  }
  .login-page .login-right-subcontainer{
      flex: 1;
      padding: 40px 40px 0px 40px;
      text-align: left;
      position: relative;
  }
  .login-page .logo-wrapper{
      display: flex; 
      align-items: center;
      margin-left: 15px;
      margin-top: 35px
  }
  .login-page .logo{
      width: 40px; 
  }
  .login-page .logo-text{
      font-weight: 800;
      letter-spacing: -1px;
      font-size: 34px;
      margin-left: 10px;
      height: 48px;
      font-family: 'Open Sans'
  }
  .login-page .close-icon{
      position: absolute; 
      top: 13px; 
      right: 14px;
      color: rgb(129, 129, 139);
      cursor: pointer;
  }
  .login-page input{
      background-color: #f2f5f7;
      height: 34px;
      border-radius: 5px;
      border: none;
      width: 91%;
      padding-left: 15px;
      padding-right: 15px;
      font-weight: 600;
      font-size: 13.5px;
      color: rgb(91, 91, 102)
  }

  .login-page input.key-input {
    width: 100%
  }

  .login-page input.activation-input{
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .login-page input:focus{
      outline: none
  }
  .login-page .label{
      font-weight: 600;
      font-size: 15px;
      margin-top: 20px;
      margin-bottom: 8px
  }
  .login-page .remember-me{
      height: 22px;
      margin-left: 10px;
      margin-top: 0px
  }
  .login-page .custom-radio{
      width: 13px; 
      height: 13px; 
      border: 1.5px solid rgb(133, 132, 138); 
      border-radius: 3px;
      cursor: pointer;
      display: flex; 
      justify-content: center;
      align-items: center;
  }
  .login-page .custom-radio.active{
      background-color: teal;
      border-color: teal
  }
  .login-page .custom-radio.active .check-icon{
      visibility: visible;
  }
  .login-page .check-icon{
      color: white; 
      margin: 0px; 
      display: block;
      visibility: hidden;
  }
  .login-page .forgot-proposal{
      font-size: 13px;
      height: 5px;
      margin-top: 0px
  }

  .login-page button{
      border: none; 
      background-color: white; 
      padding: 10px 20px; 
      font-weight: 600; 
      font-size: 15px;
      border-radius: 7px;
      box-shadow: 0px 0px 3px 3px rgba(128, 128, 128, 0.195);
      flex: 1;
      cursor: pointer;
  }
  .login-page .login-button-wrapper{
      display: flex; 
      height: 40px;
      margin-top: 25px;
  }
  .login-page .highlight-button{
      background-color: teal;
      color: white;
  }
  .login-page a{
      text-decoration: none;
      color: teal;
      font-weight: 600;
  }
  .login-page .register-proposal{
      text-align: center;
      font-size: 14px; 
      font-weight: 600;
      color: rgb(77, 76, 82)
  }
  .login-page .error-text{
      color: rgb(177, 75, 75);
      font-size: 13px;
      font-weight: 600;
      margin-top: 5px
  }

  .login-page .error-text.hidden{
    display: none
  }

  .login-page .loading-container {
    width: 50px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    position: relative;
  }